import "../../core/src/desktop/dialogs/setting/workspace-setting/storage/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/workspace-setting/storage/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W227bMAx9z1cIAwq0QFXYTpdmLvayH9gHDMMgW7TDzpYEikmcDf33wXISx2mcpmvfEku8nMNDUne/HhqYJjYSfydCaPSuUptUFBU0jxMhnpaesdjI3BoGw6nwTuUgM+A1gGlvqApLI5Gh9qnIwTDQ4+R5crf1Gwe/rTtZkl2nIh6e3nm2pEqQjmxJ4L3U4PPOyBqWHv9AKlaKrqVURYEGZPje+Js2em4rS0fnq0QyNCw95NZoRZtwcwFYLjgVSeQCso+CKkStqEQjM8ts61Qkrf/zEDNFck3KOaCAdJfbvEsts6SBJCmNS5+K++1Xlf8uyS6NliOoK7UBkgf3FnYF9H0FVKmOhPZ/UbVlWKDWLao+0WSQShxFV4en09P6GKHkdS5L5VIRz4Zc3Z8Vy+fXVeHrADP8Xm+RzKJoUP/5MOYseL1UR73dQ7Db1p6tS0VyP6KsAEgjQc5oTdoGW9YHLCSdnVNaoyn7D0c6mB/r4AIFOMJ61wGdu1TErhHeVqhHzNF41PCtC94ZDZDPh1IoCXXAQqglQ+0qxSA7jD4VBA4UX09vRY2mVs31NLq6FXFBNzdHDPQRvoQIp9vgpYSFcNZjRy1BpRhXcOhNXTjaevmOqHonoWk3Q/oIWYiwr9/cNS+knZ9OYlwab08vUHmkbh3CrlHzYtfTpylU3kHOkhSjDX03xv8e5ey/x9JwMH9AYXbKTvbKZlLGO0VgeCCsH1qxkh4qyBn0109MS/j0Uwzp2gIfgZMtma056KwD92mYuMfu3j24+wjQKW2vd5V5Wy0ZWhbCENoOCRoslIOZGW/LZp3KkTepiM4B6MLt78Zv4fKcbXFKlsPVI4TNnlpJFthKwBpWONhXi4sfCYM4vQN81z451URhXfQHUFXoPPqQwwIZZFiDqTC23f7d26GRI+k9ffgj6Pkfe1KFyu0JAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var blobCard = '_7xe32o9';
export var blobGridItemCheckbox = '_7xe32oe';
export var blobImagePreview = '_7xe32of';
export var blobManagementContainer = '_7xe32o7';
export var blobManagementControls = '_7xe32o3';
export var blobManagementName = '_7xe32o5';
export var blobManagementNameInactive = '_7xe32o6 _7xe32o5';
export var blobPreview = '_7xe32od';
export var blobPreviewContainer = '_7xe32oc';
export var blobPreviewFooter = '_7xe32oh';
export var blobPreviewGrid = '_7xe32o8';
export var blobPreviewInfo = '_7xe32oj';
export var blobPreviewName = '_7xe32oi';
export var empty = '_7xe32ob';
export var loadingContainer = '_7xe32oa';
export var spacer = '_7xe32o4';
export var storageProgressBar = '_7xe32o2';
export var storageProgressContainer = '_7xe32o0';
export var storageProgressWrapper = '_7xe32o1';
export var unknownBlobIcon = '_7xe32og';