import "../../../../blocksuite/presets/src/fragments/outline/card/outline-card.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../../../blocksuite/presets/src/fragments/outline/card/outline-card.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VV0W7bIBR9z1egSJWaByrHS7qUqt+wvU5TNV0bbLNhsAA7yab9+2Rsp5hQT5G2p6YXfO659557ePi2bcp6dygS9GuFUKMMt1xJgjQTYHnHnlcIUW4aAWeCCsFOfQAELyXmltWGoJxJy3Qf/t4ay4szzpW0TFr/KFMnbPhPLkuCMqUp0zhTp+fV79XDhcFXChawsWBb87JuBOSsUoIyvX4dyfEeDrOOSWsIkko6eqqBnNszQcnDPoqotIVMsJe11S2bwIBSxyZtTihp5lS2C804cmorgrZJcjcU5orRQHlrCNo1rkF5q43SBFFWQCtsH2oN09gwwXI7Ufczkkp1TLu8GeQ/Sq1aSQnqQN9jDEXBJcNdigWc+9ZdbmD32aeOaQHnzVI/h9SMrl9RUOf/yTef3z9MORv404xCOh9tgg7DOIKZ1VziivGysgSl45WLxidVvaPxEhoywd4g6lCCAeVgw64BuOy44Q6hAGHmEJ52JuGVGrK/4IRMPgwASvTfh0Ox7GQx5aYvws0gvoxewl2ssNutw0PcO0THxKH43xdK2n4WLKA+xGtHOVZapkFS7E42F5zjqI19kvQx0V+c9LLdBV7x6Fhdjgdl9NUStPUMwqrGnSIKpmI0YDHceaNhNUhTKF2T4acAy77c75O7+cp9nPd4ku6bcUFmlGiti+qB4ahdxwc/Dv+MGzOQPMxjmbJW1Zfw0lqM5ufNYpt6oaW2plcWPIr6vVqvFuUQ11vcnz13ianCpfbGES3ay/0Uzx2+Me7vbqGPHiQ4SNe3AmouzlFhgzTj8eadN2Z5oxvNa9CBkUPET8Z1n93LglfSF9uVJm9yfDWY/WeQTGwiU/T9twKqjgHmEMTpZsmlvfkcroR7k4lcdzNUd2wNdsMa3DRiv/vjy1Kp42t0Nf4AO/yHxdsJAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var cardContent = '_1pgm48fa _1pgm48f3';
export var cardHeader = '_1pgm48f2';
export var cardPreview = '_1pgm48f1';
export var currentModeLabel = '_1pgm48f9';
export var displayModeButton = '_1pgm48f8';
export var displayModeButtonGroup = '_1pgm48f7';
export var divider = '_1pgm48f6';
export var headerIcon = '_1pgm48f4 _1pgm48f3';
export var headerNumber = '_1pgm48f5 _1pgm48f3';
export var modeChangePanel = '_1pgm48fb';
export var outlineCard = '_1pgm48f0';